import { ssrApi } from 'api/ssr';
import { MainPage, Props } from 'components/template/home';
import { Routes } from 'constants/routes';
import { IExtendedCollection } from 'constants/types';
import { flatten, tail, zip } from 'lodash';
import { ParsedUrlQuery } from 'querystring';
import { Home } from 'store/api/home';

export default function Main({
  posts,
  title,
  bigwins,
  subtitle,
  countPosts,
  allProviders,
  countBigwins,
  firstCollection,
  collectionsArray,
}: Props) {
  return (
    <MainPage
      title={title}
      posts={posts}
      bigwins={bigwins}
      subtitle={subtitle}
      countPosts={countPosts}
      allProviders={allProviders}
      countBigwins={countBigwins}
      collectionsArray={collectionsArray}
      firstCollection={firstCollection}
    />
  );
}

const mergeArraysSkippingFirst = <T, U>(arr1: T[], arr2: U[]): (T | U)[] =>
  flatten(zip(tail(arr1), arr2)).filter((e): e is T | U => e !== undefined);

export async function getServerSideProps({
  res,
  query,
}: {
  res: any;
  query: ParsedUrlQuery;
}) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  const { reg } = query;

  const region = reg ? reg.toString() : null;

  const homeData: Home = await ssrApi.home.get({ region });

  const {
    title,
    bigwins,
    subtitle,
    allSlots,
    blogPosts,
    allCasinos,
    bigwinsCount,
    allProviders,
    allSlotsCount,
    blogPostsCount,
    allCasinosCount,
    slotsCollections,
    casinoCollections,
  } = homeData;

  const { count: countPosts } = await ssrApi.blog.list({});

  const allCasinosCollection = {
    _id: '',
    slug: '',
    name: 'All Casinos',
    description: 'All',
    posts: [],
    customUrl: Routes.allCasinos,
    casinos: allCasinos,
    createdAt: 'all',
    updatedAt: 'all',
    isVisible: true,
    isVisibleOnMain: true,
    type: 'casino',
    count: allCasinosCount,
    casinosCount: allCasinosCount,
  } as unknown as IExtendedCollection;

  const allSlotsCollection = {
    _id: '',
    slug: '',
    customUrl: Routes.allSlots,
    name: 'All Slots',
    description: 'All',
    posts: [],
    slots: allSlots,
    createdAt: 'all',
    updatedAt: 'all',
    isVisible: true,
    isVisibleOnMain: true,
    type: 'slot',
    count: allSlotsCount,
    slotsCount: allSlotsCount,
  } as unknown as IExtendedCollection;

  const [firstCollection, ...restCasinos] = casinoCollections;

  const extendedCollections = mergeArraysSkippingFirst(
    [firstCollection, allCasinosCollection, ...restCasinos],
    [allSlotsCollection, ...slotsCollections]
  );

  return {
    props: {
      title,
      bigwins,
      subtitle,
      countPosts,
      allProviders,
      blogPostsCount,
      posts: blogPosts,
      countBigwins: bigwinsCount,
      firstCollection: firstCollection,
      collectionsArray: extendedCollections,
    },
  };
}
